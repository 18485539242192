import { Input, Image, Button, Row, Col } from 'antd';
import classnames from 'classnames';
import { EgGrid, Permission } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { ENUM_STATUS, Store } from './store';

const store = new Store();

@observer
class Left extends React.Component {
  render() {
    const {
      onlyCodeRef,
      handleOnlyCodePressEnter,
      status,
      onlyCode,
      onlyCodeChange,
      paramItem,
      weight,
      weightChange,
      weightRef,
      handleWeightPressEnter,
      handleOpenParamModal,
    } = store;
    return (
      <div className={styles.leftNode}>
        <div className={styles.leftNodeHeader}>
          <span className={styles.title}>
            收货
          </span>
          <div>
            <Button
              onClick={handleOpenParamModal}
              type="primary"
            >
              <i className="icon-setup"/>
              &nbsp;
              参数设置
            </Button>
          </div>
        </div>
        <div className={styles.leftNodeBody}>
          <div className={styles.card}>
            <p>
              请扫描唯一码
            </p>
            <Input
              autoFocus
              onChange={onlyCodeChange}
              onPressEnter={handleOnlyCodePressEnter}
              placeholder="请扫描唯一码"
              ref={onlyCodeRef}
              size="large"
              value={onlyCode}
            />
          </div>
          <div className={styles.card}>
            <p>
              商品重量
            </p>
            <Input
              disabled={paramItem.weightIntercept === false}
              onChange={weightChange}
              onPressEnter={handleWeightPressEnter}
              placeholder="请输入重量"
              ref={weightRef}
              size="large"
              suffix="千克"
              value={weight}
            />
          </div>
          {
            status ? (
              <div className={classnames({
                [styles.prompt]: true,
                [styles.failure]: status === ENUM_STATUS.failure.value,
              })}
              >
                <span>
                  {Object.values(ENUM_STATUS).find((item) => item.value === status)?.label}
                </span>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}

@observer
class Right extends React.Component {
  render() {
    const {
      egGridModel,
      getCurrentRow,
    } = store;
    return (
      <div className={styles.rightNode}>
        <div className={styles.rightNodeHeader}>
          <span className={styles.results}>
            扫描结果
          </span>
          <Permission permissionId="2672_718">
            <Button
              onClick={() => window?.top?.egenie?.openTab('/egenie-cloud-wms/scanGoods/receivingRecord/index', 'arrivalRecord', '到货记录', 'shjc')}
              type="link"
            >
              ··· 查看更多
            </Button>
          </Permission>
        </div>
        {egGridModel.rows.length ? (
          <div className={styles.rightNodeBody}>
            <div>
              <span className={styles.contentTitle}>
                <i className={styles.icon}/>
                商品图片
              </span>
              <div className={styles.contentImage}>
                <div className={styles.image}>
                  <Image
                    className={styles.pic}
                    placeholder
                    preview={false}
                    src={getCurrentRow.pic}
                    width={440}
                  />
                </div>
                <div className={styles.information}>
                  <span>
                    {getCurrentRow.goodsNo}
                  </span>
                  <span>
                    {getCurrentRow.color}
                    &nbsp;
                    &nbsp;
                    {getCurrentRow.size}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.goodsDetail}>
              <span className={styles.contentTitle}>
                <i className={styles.icon}/>
                商品明细
              </span>
              <div className={styles.egGridStore}>
                <EgGrid store={store.egGridModel}/>
              </div>
            </div>
          </div>
        ) : ''}
      </div>
    );
  }
}

export default function() {
  return (
    <Row className={styles.page}>
      <Col span={6}>
        <Left/>
      </Col>
      <Col span={18}>
        <Right/>
      </Col>
    </Row>
  );
}
