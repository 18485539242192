import type { InputRef } from 'antd';
import { message } from 'antd';
import { formatPrice, renderModal, destroyAllModal } from 'egenie-common';
import type { BaseData } from 'egenie-utils';
import { EgGridModel, ImgFormatter, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { getAndPlayVoice, playVoice, voiceData } from '../../../utils';
import { ParamModal } from './paramModal';
import type { ArrivalItem, ParamItem } from './types';

export const ENUM_STATUS = {
  singlePass: {
    value: 'singlePass',
    label: '单件',
    voiceText: '单件',
  },
  multiplePass: {
    value: 'multiplePass',
    label: '多件',
    voiceText: '多件',
  },
  normalPass: {
    value: 'normalPass',
    label: '成功',
  },
  failure: {
    value: 'failure',
    label: '失败',
  },
};

export class Store {
  constructor() {
    this.getParams();
  }

  @observable public paramItem: ParamItem = {
    weightIntercept: false,
    arrivalVoice: false,
  };

  @action public getParams = () => {
    request<BaseData<ParamItem>>({ url: '/api/cloud/baseinfo/rest/param/config/query' })
      .then((info) => this.handleParamChange(info.data));
  };

  @action public handleOpenParamModal = () => {
    renderModal(
      <ParamModal
        callback={((data) => {
          return request({
            url: '/api/cloud/baseinfo/rest/param/config/save',
            method: 'post',
            data,
          })
            .then(() => {
              this.handleParamChange(data);
              message.success('保存成功');
              destroyAllModal();
            });
        })}
        initialValues={this.paramItem}
        onCancel={destroyAllModal}
      />
    );
  };

  @action public handleParamChange = (params: ParamItem): void => {
    this.weight = '';
    this.onlyCode = '';
    this.status = null;
    this.paramItem = {
      ...this.paramItem,
      ...params,
    };

    if (this.paramItem.weightIntercept) {
      // 重量还被禁止，无法直接获取焦点
      setTimeout(() => {
        this.weightRef.current.focus();
      });
    } else {
      this.onlyCodeRef.current.focus();
    }
  };

  @observable public weight = '';

  @observable.ref public weightRef = React.createRef<InputRef>();

  @action public weightChange = (e) => {
    this.weight = e.target.value;
  };

  @action public handleWeightPressEnter = (e) => {
    this.weight = e.target.value;
    this.onlyCode = '';
    this.status = null;
    this.onlyCodeRef.current.focus();
  };

  @observable public status: string | null = null;

  @observable public onlyCode = '';

  @observable.ref public onlyCodeRef = React.createRef<InputRef>();

  @action public onlyCodeChange = (e) => {
    this.onlyCode = e.target.value;
  };

  @action public handleOnlyCodePressEnter = (e) => {
    const onlyCode = e.target.value;
    const weight = formatPrice(this.weight, 3);

    if (this.paramItem.weightIntercept) {
      if (!weight) {
        this.weight = '';
        this.onlyCode = '';
        this.weightRef.current.focus();
        playVoice(voiceData.请输入重量);
        return;
      }

      if (!onlyCode) {
        this.onlyCode = '';
        this.onlyCodeRef.current.focus();
        playVoice(voiceData.请输入唯一码);
        return;
      }

      this.weight = '';
      this.onlyCode = '';
      this.weightRef.current.focus();
    } else {
      this.onlyCode = '';
      this.weight = '';
      this.onlyCodeRef.current?.focus();

      if (!onlyCode) {
        playVoice(voiceData.请输入唯一码);
        return;
      }
    }

    request<BaseData<ArrivalItem>>({
      method: 'POST',
      url: '/api/cloud/wms/rest/goods/arrival/save',
      data: {
        uniqueCode: onlyCode,
        weight: weight ? weight : undefined,
      },
    })
      .then((req) => {
        if (this.paramItem.arrivalVoice) {
          if (req.data?.isSingle) {
            getAndPlayVoice(ENUM_STATUS.singlePass.voiceText);
            this.status = ENUM_STATUS.singlePass.value;
          } else {
            getAndPlayVoice(ENUM_STATUS.multiplePass.voiceText);
            this.status = ENUM_STATUS.multiplePass.value;
          }
        } else if (this.paramItem.weightIntercept) {
          playVoice(voiceData.通过);
          this.status = ENUM_STATUS.normalPass.value;
        } else {
          playVoice(voiceData.di);
          this.status = ENUM_STATUS.normalPass.value;
        }

        this.egGridModel.rows = [
          {
            ...(req.data || {}),
            id: nanoid(),
          },
          ...this.egGridModel.rows,
        ];
        this.egGridModel.resetCursorRow();
      })
      .catch(() => {
        playVoice(voiceData.wrong);
        this.status = ENUM_STATUS.failure.value;
      });
  };

  public egGridModel = new EgGridModel({
    columns: [
      {
        key: 'uniqueCode',
        name: '唯一码',
        width: 200,
      },
      {
        key: 'skuNo',
        name: 'sku编码',
        resizable: true,
        width: 200,
      },
      {
        key: 'pic',
        name: '图片',
        formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
      },
      {
        key: 'color',
        name: '颜色',
      },
      {
        key: 'size',
        name: '尺码',
        sortable: true,
      },
    ].map((v) => ({
      resizable: true,
      sortable: false,
      ...v,
    })),
    rows: [],
    primaryKeyField: 'id',
    sortByLocal: false,
    showCheckBox: false,
    showEmpty: false,
    showPager: false,
  });

  public get getCurrentRow() {
    let row: {
      [key: string]: string;
    };
    const {
      rows,
      cursorRow,
    } = this.egGridModel;
    if (cursorRow && cursorRow.id) {
      row = cursorRow;
    } else {
      row = rows[0];
    }
    return row;
  }
}
