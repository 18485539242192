import { Form, Modal, Switch } from 'antd';
import React from 'react';
import type { ParamItem } from './types';

interface ParamModalProps {
  onCancel: () => void;
  initialValues: Partial<ParamItem>;
  callback: (params: ParamItem) => Promise<unknown>;
}

export function ParamModal({
  onCancel,
  callback,
  initialValues,
}: ParamModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: ParamItem; }>({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      okText="保存"
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="参数设置"
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={initialValues}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="称重"
          name="weightIntercept"
          valuePropName="checked"
        >
          <Switch/>
        </Form.Item>
        <Form.Item
          label="单多件声音提示"
          name="arrivalVoice"
          valuePropName="checked"
        >
          <Switch/>
        </Form.Item>
      </Form>
    </Modal>
  );
}
